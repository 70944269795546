/* #root{
    background-color: steelblue;
    height: 100%;
    width: 100%;
    position: absolute;
}
*/

#firebaseui_container{
    margin-top: 30%;
}